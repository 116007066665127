import React from "react";
import Router from "next/router";

const RequestForQuotation = ({ category_lv1, category_lv2 }) => {
  const handleClick = () => {
    const initalRfq = { category_lv1, category_lv2 };
    sessionStorage.__draftRfq = JSON.stringify(initalRfq);
    Router.push("/yeu-cau-bao-gia/gui-yeu-cau");
  };

  return (
    <div className="bg-white px-3 py-2">
      {/* <Link href="/yeu-cau-bao-gia">
        <a>
          <Image
            loading="lazy"
            src="/static/images/BannerMobile-YeuCauBaoGia.png"
            layout="responsive"
            width={395}
            height={110}
            alt="yeu cau bao gia si"
          />
        </a>
      </Link> */}
      <div>
        <div className="text-lg font-bold">Chưa tìm thấy sản phẩm phù hợp?</div>
      </div>
      <div className="font-90 text-gray-600">
        Hãy sử dụng tính năng Yêu cầu báo giá để mau chóng tìm được sản phẩm bạn mong muốn!
      </div>

      <div onClick={handleClick} className="text-orange-500 border-orange-500 border text-center p-1 rounded my-2">
        Gửi yêu cầu ngay
      </div>
    </div>
  );
};

export default RequestForQuotation;
