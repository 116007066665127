import React from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import Slider from "react-slick";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { sendWebViewEvent, WebviewEvents } from "../../../helpers/webview";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  autoplay: true,
  autoplaySpeed: 5000,
};

const PromotedSlider = props => {
  const mobileAppVersion = useSelector(({ common }) => common.mobileAppVersion);

  const handleBannerClick = useCallback((e, url) => {
    if (mobileAppVersion >= 2) {
      e.preventDefault();
      sendWebViewEvent(WebviewEvents.Push, [
        "WebviewWhitelistScreen",
        {
          url: url,
        },
      ]);
    }
  }, []);

  return (
    <div>
      <div className="promoted-slider">
        {props.items.length > 0 && (
          <Slider {...settings}>
            {props.items.map((item, index) => (
              <Link
                href={item.link}
                prefetch={false}
                key={item.id}
                onClick={e => handleBannerClick(e, item.link)}
              >
                <Image
                  src={item.banner}
                  layout="responsive"
                  width={props.itemWidth}
                  height={props.itemHeight}
                  // className="max-w-full h-auto"
                  alt={item.title}
                  priority={index === 0}
                  unoptimized
                />
              </Link>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default PromotedSlider;
