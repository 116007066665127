import React, { useState, useCallback, useEffect } from "react";

const ReviewComment = ({ comment, setComment }) => {
  const [errors, setErrors] = useState({});

  const handleChange = useCallback((event) => {
    event.persist();
    setComment(event.target.value);
  }, []);

  return (
    <div>
      <textarea
        style={{ resize: "none" }}
        rows="3"
        className="form-control"
        placeholder="Không bắt buộc"
        onChange={handleChange}
        value={comment}
      ></textarea>
    </div>
  );
};
export default React.memo(ReviewComment);
