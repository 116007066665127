import axios from "../axios";
import qs from "query-string";
import getOr from "lodash/fp/getOr";

export const getBanners = async query => {
  try {
    const { data } = await axios.get(
      `/uab/api/get?${qs.stringify(query, { skipNull: true })}`
    );
    return getOr([], "data[0].ads_content", data);
  } catch (error) {
    return [];
  }
};
