import React from "react";

import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    right: "0",
    bottom: "50%",
    left: "0",
    width: "95%",
    maxWidth: "600px",
    marginLeft: "auto",
    marginRight: "auto",

    background: "#fff",
    transform: "translate(0, 50%)",
    boxShadow:
      "0 31px 41px 0 rgba(32,42,53,.2), 0 2px 16px 0 rgba(32,42,54,.08)",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: "0.5rem 0.7rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    maxHeight: "60vh",
    overflowY: "auto",
  },
  footer: {
    borderTop: "1px solid rgba(0,0,0,.15)",
    display: "flex",
    justifyContent: "center",
    padding: "0.5rem 0.85rem",
  },
  action: {
    padding: "0.3rem 0.5rem",
    boxShadow: "none",
    textTransform: "initial",
    color: "#fff",
  },
});

const TSModal = props => {
  const classes = useStyles();

  return (
    <Modal open={props.open} onClose={undefined} style={props.style || {}}>
      <div className={classes.root}>
        <div className={classes.header + " border-b"}>
          <h5 className="mb-0">{props.title}</h5>
          {props.onClose && <CloseIcon onClick={props.onClose} />}
        </div>
        <div className={classes.content + " p-2"}>{props.children}</div>
        <div className={classes.footer}>
          {props.footer && props.footer}
          {(props.secondaryAction || props.primaryAction) && (
            <div className="ml-auto">
              {props.secondaryAction && props.secondaryAction.content && (
                <Button
                  variant="text"
                  className={"!mr-2 bg-secondary"}
                  onClick={props.secondaryAction.onAction}
                >
                  {props.secondaryAction.content}
                </Button>
              )}
              {props.primaryAction &&
                props.primaryAction.content &&
                !props.primaryAction.hide && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.action}
                    onClick={props.primaryAction.onAction}
                    disabled={props.primaryAction.loading}
                  >
                    {props.primaryAction.content}
                  </Button>
                )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(TSModal);
