const handleImageDomain = url => {
  if (url) {
    if (url.indexOf("http") > -1) {
      return url;
    }
    return `https://scdn.thitruongsi.com/image${url}`;
  }
  return "https://s3.thitruongsi.com/static/no-image.jpeg";
};

export default handleImageDomain;
