import React from "react";

const Image = props => {
  return (
    <div className="ts-image">
      <i className="ts-image__sizer"></i>
      <img
        {...props}
        className={`ts-image__img${
          props.className ? ` ${props.className}` : ""
        }`}
      />
    </div>
  );
};

export default Image;
