const priceRangeAndMask = {
  1000: "xxx",
  10000: "x.xxx",
  100000: "xx.xxx",
  1000000: ".xxx.xxx",
  100: "xx",
};

export default function formatPriceDisplay(price, hide_prices = false) {
  try {
    if (hide_prices) {
      return "Liên hệ";
    }
    return price.toLocaleString("vi-VN") + "₫";
  } catch (error) {
    return "---";
  }
}
