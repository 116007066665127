import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";

const Card = React.forwardRef(
  ({ title, headerAction, children, titleUrl, styles = {} }, ref) => {
    return (
      <section className="ts-card" ref={ref} style={{ ...styles }}>
        <div className="ts-card__header">
          {titleUrl && (
            <Link href={titleUrl}>
              <h2 className="ts-card__title">{title}</h2>
            </Link>
          )}
          {!titleUrl && <h2 className="ts-card__title">{title}</h2>}
          {headerAction && (
            <div
              className="ts-card__action text-gray-700"
              onClick={headerAction.onAction}
            >
              {headerAction.content}
            </div>
          )}
        </div>
        <div className="ts-card__content">{children}</div>
      </section>
    );
  }
);

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerAction: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onAction: PropTypes.func,
  }),
  children: PropTypes.node,
};

export default Card;
