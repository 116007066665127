import axios from "../axios";
import qs from "query-string";

export const getShopInfo = async slug => {
  const { data } = await axios.get(`/v1/user/api/shop/${slug}`);
  return data.data.shop;
};

export const getShopsInfo = async list_ids => {
  const { data } = await axios.post(`/v1/user/api/shops/shops-by-list-id`, {
    list_ids,
  });

  return data.data.shops;
};

export const getShops = async query => {
  try {
    const { data } = await axios.get(
      `/v2/search/shops${query ? `${query}` : ""}`
    );
    return data;
  } catch (error) {
    return { total: 0, shops: [] };
  }
};

export const followShop = async shop_id => {
  const { data } = await axios.post(`/v1/user/api/shops/${shop_id}/follow`);

  return data;
};

export const unfollowShop = async shop_id => {
  const { data } = await axios.delete(`/v1/user/api/shops/${shop_id}/follow`);

  return data;
};

export const getFollowingShop = async (query = {}) => {
  const queryString = qs.stringify(query);

  const { data } = await axios.get(
    `/v1/user/api/v4/me/shops/following${queryString ? `?${queryString}` : ""}`
  );

  return data.data;
};

export const checkFollowShop = async shop_id => {
  const { data } = await axios.get(
    `/v1/user/api/shops/${shop_id}/is-following`
  );
  return data;
};

export const checkBrandNameExist = async brand_name => {
  const { data } = await axios.get(
    `/v1/user/api/shops/exists-name?name=${brand_name}`
  );

  return data.data;
};
