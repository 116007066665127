import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unitsGetRequest } from "../../redux/actions/common.action";
import Router from "next/router";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Select from "@material-ui/core/Select";
import { gaGenericEvent } from "../../api/Analytics/enhancedEcommerce";
const useStyles = makeStyles({
  textField: {
    marginBottom: "1rem",
  },
  submitBtn: {
    color: "#fff",
    textTransform: "initial",
  },
  select: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: 10,
    },
    marginBottom: "1rem",
  },
});
const QuickRFQWidget = props => {
  const classes = useStyles();
  const units = useSelector(({ common }) => common.units);
  const dispatch = useDispatch();
  const [rfq, setRfq] = useState({});
  const [errors, setErrors] = useState({});
  const isSearchEngineBot = useSelector(
    ({ common }) => common.isSearchEngineBot
  );

  useEffect(() => {
    if (!units.length && !isSearchEngineBot) {
      dispatch(unitsGetRequest());
    }
  }, []);

  const handleChange = useCallback((field, value) => {
    setRfq(old => ({ ...old, [field]: value }));
    setErrors(old => ({ ...old, [field]: undefined }));
  }, []);

  const handleSubmit = useCallback(() => {
    sessionStorage.__draftRfq = JSON.stringify(rfq);
    gaGenericEvent("rfq.widget", "submit", "web_mobile");
    Router.push(`/yeu-cau-bao-gia/gui-yeu-cau`);
  }, [rfq]);

  return (
    <>
      <div className="rfq-widget__container">
        <div className="rfq-widget bg-white">
          <div className="p-3">
            <div className="text-center">
              <h2 className="rfq-widget__title text-orange font-semibold">
                Tìm nhà cung cấp cho bạn
              </h2>
              <div className="rfq-widget__sub-title mt-1">
                Gửi yêu cầu báo giá, nhận nhiều báo giá sỉ từ nhiều nhà cung cấp
                trên Thị Trường Sỉ
              </div>
            </div>

            <form className="mt-4" onSubmit={handleSubmit}>
              <FormGroup>
                <TextField
                  // label="Tên sản phẩm"
                  placeholder="Bạn đang tìm sản phẩm gì?"
                  variant="outlined"
                  size="small"
                  className={classes.textField}
                  value={rfq.title}
                  onChange={e => handleChange("title", e.target.value)}
                  spellCheck={false}
                  error={Boolean(errors.title)}
                  helperText={errors.title}
                />

                <div className="flex justify-between items-start mb-3">
                  <TextField
                    label="Số lượng cần"
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="number"
                    className="w-1/2"
                    value={rfq.quantity}
                    onChange={e => handleChange("quantity", e.target.value)}
                    error={Boolean(errors.quantity)}
                    helperText={errors.quantity}
                  />
                  <Select
                    variant="outlined"
                    className={classes.select}
                    native
                    style={{ minWidth: 85 }}
                    value={rfq.quantity_unit}
                    onChange={e =>
                      handleChange("quantity_unit", e.target.value)
                    }
                    error={Boolean(errors.quantity_unit)}
                    helperText={errors.quantity_unit}
                  >
                    {units.map(unit => (
                      <option key={unit.id} value={unit.name}>
                        {unit.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <TextField
                  multiline
                  label="Mô tả về nhu cầu, sản phẩm cần tìm"
                  variant="outlined"
                  className={classes.textField}
                  error={Boolean(errors.description)}
                  helperText={
                    errors.description ||
                    "Nhập thông tin mô tả càng rõ ràng càng mau chóng nhận được báo giá từ nhà cung cấp"
                  }
                  onChange={e => handleChange("description", e.target.value)}
                  spellCheck={false}
                />
              </FormGroup>
            </form>
          </div>
          <div className="p-2">
            <button
              className="btn btn-orange rfq-widget__submit-btn"
              onClick={handleSubmit}
            >
              Tiếp tục
            </button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .rfq-widget__title {
          font-size: 1.4rem;
          margin-bottom: 0rem;
        }

        .rfq-widget__sub-title {
          font-size: 0.875rem;
        }

        .rfq-widget__submit-btn {
          height: 45px;
          border-radius: 5px;
          background-color: #f58220;
          color: #fff;
          border: 0;
          width: 100%;
        }
      `}</style>
    </>
  );
};

export default React.memo(QuickRFQWidget);
