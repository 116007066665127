import React, { useEffect } from "react";
import { getBlogPosts } from "../../../api/WPJson";
import BlogPostItem from "./BlogPostItem";
import { useSelector } from "react-redux";

const PinnedBlogPosts = () => {
  const blogPostsSSR = useSelector(({ homepage }) => homepage.blogPosts);
  const [posts, setPosts] = React.useState(blogPostsSSR);

  useEffect(() => {
    if (!blogPostsSSR.length) {
      getBlogPosts({
        categories: [774],
        per_page: 4,
        context: "embed",
        _embed: true,
      }).then(response => {
        setPosts(response);
      });
    }
  }, []);

  return (
    <div className="bg-white mb-2">
      <div className="mx-2 py-2 text-center text-lg">Chia sẻ kinh nghiệm</div>
      <div className="flex items-stretch overflow-x-auto scrollbar-hide">
        {posts.map(post => (
          <BlogPostItem post={post} />
        ))}
      </div>

      <div className="text-center">
        <a
          href="https://thitruongsi.com/kinh-nghiem/"
          className="py-2 inline-block text-[#f58220]"
        >
          Xem tất cả &raquo;
        </a>
      </div>
    </div>
  );
};

export default PinnedBlogPosts;
