import React from "react";

const BlogPostItem = ({ post }) => {
  return (
    <a
      href={post.link}
      className="ml-1 mb-2 bg-white p-2 rounded-md max-w-[45%] flex-shrink-0"
    >
      <div className="relative">
        <div className="pt-[75%]"></div>
        <div className="absolute rounded-md top-0 left-0 right-0 bottom-0 ">
          <img
            className="w-full h-full object-cover rounded-md"
            src={
              post._embedded?.["wp:featuredmedia"][0].media_details.sizes.medium
                .source_url
            }
            alt={post.title.rendered}
          />
        </div>
      </div>

      <p
        className="text-sm mt-1 text-gray-700 line-clamp-3"
        dangerouslySetInnerHTML={{ __html: post.title.rendered }}
      ></p>
    </a>
  );
};

export default BlogPostItem;
