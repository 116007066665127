import React, { useMemo, useCallback, forwardRef } from "react";
import format from "date-fns/format";
import vi from "date-fns/locale/vi";

import Link from "next/link";

import isFullUrl from "#Root/helpers/isFullUrl";

import Image from "../Image";
import { useDispatch } from "react-redux";
import { prepareProductDetailState } from "../../../../redux/actions/products.action";
import differenceInDays from "date-fns/differenceInDays";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

const ClassifiedsProductCard = forwardRef(
  (
    {
      id,
      slug,
      shop_id,
      product_id,
      image_thumb,
      title,
      created_at,
      prices,
      unit_name,

      sponsored = false,
      clickURL,
      onClick,
      is_classifieds,
      classifieds,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const getImageUrl = useMemo(() => {
      if (!image_thumb) {
        return "/static/images/no-image-thumbnail.jpg";
      }
      if (isFullUrl(image_thumb)) {
        return image_thumb;
      }
      return process.env.SCDN_IMAGE_URL + image_thumb;
    }, [image_thumb]);

    // const isDeal = is_deal && deal_state === "running";

    const handleClick = useCallback(() => {
      dispatch(
        prepareProductDetailState({
          id,
          product_id,
          shop_id,
          title,
          image_thumb,
          prices,
          unit_name,
          is_classifieds,
          classifieds,
        })
      );

      if (onClick) {
        onClick({ id, title });
      }
    }, []);

    const createdAt = useMemo(() => {
      if (!created_at) {
        return "---";
      }
      const days = differenceInDays(new Date(), new Date(created_at));
      if (days > 7) {
        return format(new Date(created_at), "dd-MM-yyyy HH:mm");
      }
      return `${formatDistanceToNow(new Date(created_at), {
        locale: vi,
      })} trước`;
    }, [created_at]);

    return (
      <div className="ts-classified" onClick={handleClick} ref={ref}>
        <Link
          href={
            sponsored
              ? clickURL
              : `/product-details?slug=${slug}&id=${product_id}`
          }
          as={sponsored ? clickURL : `/${slug}-${product_id}.html`}
        >
          {/* <div className="relative"> */}
          <div className="flex items-start">
            <div className="ts-classified__image-container">
              <Image
                alt={title + ` giá sỉ`}
                src={getImageUrl}
                loading="lazy"
                decoding="async"
              />
            </div>

            <div className="pl-1">
              <div className="ts-classified__title">{title}</div>
              <div className="mt-1 flex flex-wrap items-start">
                <span className="ts-classified__badge mr-2 mb-2">
                  {classifieds.label} / {classifieds.area}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
);

export default React.memo(ClassifiedsProductCard);
