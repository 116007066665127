import React, { useEffect, useState } from "react";
import { getBanners } from "../../api/Banners";

const PrimaryAds = props => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    getBanners({
      position: "home_primary",
      platform: "web_desktop",
      limit: 20,
    }).then(setBanners);
  }, []);

  return (
    <>
      <div
        className="flex bg-white primary-banners"
        style={{ overflowX: "auto" }}
      >
        {banners.map(banner => (
          <a href={banner.link} key={banner.id}>
            <img
              src={banner.banner}
              alt={banner.title}
              className="max-w-none h-auto w-72 rounded m-3"
              loading="lazy"
              decoding="async"
            />
          </a>
        ))}
      </div>
      <style jsx>{`
        .primary-banners {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .primary-banners::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </>
  );
};

export default React.memo(PrimaryAds);
