import React from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { sendWebViewEvent, WebviewEvents } from "../../../helpers/webview";

const Navigation = props => {
  const mobileAppVersion = useSelector(({ common }) => common.mobileAppVersion);
  const authToken = useSelector(({ user }) => user.token);
  const refreshToken = useSelector(({ user }) => user.refresh_token);

  const isLoggedIn = useMemo(() => {
    return Boolean(authToken);
  }, [authToken]);

  const dropshipURL = useMemo(() => {
    return isLoggedIn
      ? `https://dropship.thitruongsi.com/login/callback?access_token=${authToken}&refresh_token=${refreshToken}&next=${encodeURIComponent(
          "/?onboard=true"
        )}&utm_source=tts&utm_medium=home_menu&utm_campaign=dropship`
      : "https://dropship.thitruongsi.com?utm_source=tts&utm_medium=home_menu&utm_campaign=dropship";
  }, [isLoggedIn, authToken, refreshToken]);

  const menu = [
    {
      title: "Danh mục sản phẩm",
      url: "/category",
      icon_src: "/static/icons/DanhMucSP-Icon.png",
      prefetch: true,
    },
    {
      title: "Yêu cầu báo giá",
      url: "/yeu-cau-bao-gia",
      icon_src: "/static/icons/YeuCauBaoGia-Icon.png",
      prefetch: false,
    },
    {
      title: "Bán hàng không cần vốn",
      url: dropshipURL,
      icon_src: "/static/icons/money-bag.png",
      prefetch: false,
      isNew: true,
      onClick:
        mobileAppVersion >= 2
          ? () => {
              sendWebViewEvent(WebviewEvents.Push, [
                "NotWhitelistScreen",
                {
                  url: dropshipURL,
                },
              ]);
            }
          : undefined,
    },
    // {
    //   title: "Đang tuyển NPP, Đại lý, CTV",
    //   url: "/tim-nha-phan-phoi",
    //   icon_src: "/static/icons/TuyenDaiLyCTV.png",
    //   prefetch: false,
    //   isNew: false,
    //   // hide: process.env.APP_ENV !== "development",
    // },
    {
      title: "Hướng dẫn mua hàng",
      url: "https://thitruongsi.com/kinh-nghiem/hoi-dap/lam-sao-toi-co-the-mua-hang-tren-thi-truong-si/?utm_source=web_mobile&utm_medium=left_slide&utm_campaign=help&_ga=2.115239940.2020564921.1578906424-2124646658.1574821426",
      icon_src: "/static/icons/HDMuaHang-Icon.png",
      prefetch: false,
    },
    {
      title: "Bán trên Thitruongsi",
      url: "https://banhang.thitruongsi.com",
      icon_src: "/static/icons/TroThanhNBS-Icon.png",
      prefetch: false,
      hide: true,
    },
  ];
  return (
    <>
      <div className="px-2 py-4 bg-white">
        <ul className="list-unstyled menu mb-0">
          {menu.map((menuItem, index) => {
            if (menuItem.onClick) {
              return (
                <li className="menu-item" key={index}>
                  <a
                    className="inline-block relative"
                    onClick={menuItem.onClick}
                  >
                    <Image
                      layout="fixed"
                      src={menuItem.icon_src}
                      width={30}
                      height={30}
                      className="mb-1 mx-auto"
                      alt={menuItem.title}
                      decoding="async"
                    />
                    <div className="menu-item__link">{menuItem.title}</div>
                    {menuItem.isNew && (
                      <span className="bg-red-500 text-white px-2 rounded-full absolute text-xs -top-2 right-1 new-badge">
                        Mới
                      </span>
                    )}
                  </a>
                </li>
              );
            }
            if (!menuItem.hide) {
              return (
                <li className="menu-item" key={index}>
                  <Link href={menuItem.url} legacyBehavior>
                    <a className="inline-block relative">
                      <Image
                        layout="fixed"
                        src={menuItem.icon_src}
                        width={30}
                        height={30}
                        className="mb-1 mx-auto"
                        alt={menuItem.title}
                        decoding="async"
                      />
                      <div className="menu-item__link">{menuItem.title}</div>
                      {menuItem.isNew && (
                        <span className="bg-red-500 text-white px-2 rounded-full absolute text-xs -top-2 right-1 new-badge">
                          Mới
                        </span>
                      )}
                    </a>
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </div>
      <style jsx>{`
        .menu {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 10px;
        }
        .menu-item {
          text-align: center;
        }

        .menu-item__link {
          font-size: 0.8rem;

          color: #333;
          line-height: 17px;
        }
        :global(.new-badge) {
          overflow: hidden;
        }

        :global(.new-badge:before) {
          content: "";
          position: absolute;
          top: 0;
          left: -50px;
          width: 70px;
          height: 100%;
          background: rgba(255, 255, 255, 0.7);
          transform: skewX(-30deg);
          animation-name: slide;
          animation-duration: 2s;
          animation-timing-function: ease-in-out;
          animation-delay: 0.3s;
          animation-iteration-count: infinite;
          animation-direction: alternate;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.2) 77%,
            rgba(255, 255, 255, 0.7) 92%,
            rgba(255, 255, 255, 0) 100%
          );
        }
        @keyframes slide {
          0% {
            left: -70px;
            top: 0;
          }
          100% {
            left: 50px;
            top: 0;
          }
        }
      `}</style>
    </>
  );
};

export default React.memo(Navigation);
