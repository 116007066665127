import React, { useEffect, useState } from "react";
import Card from "../common/Card";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import { searchProducts } from "../../api/Products";
import useWindowSize from "../../hooks/useWindowSize";
import ProductCard from "../common/ProductCard";
import { useRouter } from "next/router";

const MallProducts = props => {
  const router = useRouter();
  const size = useWindowSize();

  const [products, setProducts] = useState([]);

  useEffect(() => {
    searchProducts("?limit=6&filter_only_mall=true", "graphql").then(res => {
      setProducts(res.products);
    });
  }, []);

  return (
    <Card
      title={
        <div className="text-[#d50000] font-bold relative">
          TTS MALL
          <span className="bg-[#f58220] text-white px-2 rounded-full absolute text-xs top-1 -right-12 new-badge">
            Mới
          </span>
        </div>
      }
      headerAction={{
        content: <div className="text-[#d50000]">Xem tất cả &raquo;</div>,
        onAction: () => router.push("/mall"),
      }}
    >
      <div className="grid grid-cols-3 text-xs items-center mb-4">
        <div className="text-center flex items-center justify-center">
          <CheckCircleOutline fontSize="inherit" />
          <span className="ml-1">Chính hãng 100%</span>
        </div>
        <div className="text-center flex items-center justify-center">
          <CheckCircleOutline fontSize="inherit" />
          <span className="ml-1">Tích lũy TTS Xu</span>
        </div>
        <div className="text-center flex items-center justify-center">
          <CheckCircleOutline fontSize="inherit" />
          <span className="ml-1">Đổi trả hàng lỗi</span>
        </div>
      </div>

      <div className="flex flex-nowrap overflow-auto hide-scrollbar gap-x-2">
        {products.map(product => (
          <div className="flex-shrink-0" style={{ width: size.width / 2.3 }}>
            <ProductCard key={product.id} {...product} showVipBusinessLabel />
          </div>
        ))}
      </div>
    </Card>
  );
};

export default MallProducts;
