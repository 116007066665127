import React from "react";
import ReviewItem from "./ReviewItem";

const ModalReviewShop = ({ userId, shops, setOpenModal, onRateSuccess }) => {
  return (
    <div>
      <div className="px-3 py-2 bg-green-100 text-green-900 rounded-md">
        Gần đây bạn đã liên hệ với những nhà cung cấp dưới đây.
        <br />
        Hãy để lại đánh giá của bạn để đội ngũ chúng tôi ngày càng nâng cao chất
        lượng dịch vụ bạn nhé!
      </div>
      <div className="p-0 mt-4 mb-4 mx-auto">
        {!!shops &&
          shops.map(item => (
            <>
              <ReviewItem
                shopId={item.shop_id}
                userId={userId}
                key={item.shop_id}
                setOpenModal={setOpenModal}
                onRateSuccess={onRateSuccess}
              />
            </>
          ))}
      </div>
    </div>
  );
};

export default ModalReviewShop;
