import React, { useState, useCallback, useEffect } from "react";
import axios from "../../api/axios";
import Modal from "../common/Modal";
import getOr from "lodash/fp/getOr";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import ModalReviewShop from "./ModalReviewShop";
import { getNotRatedShops } from "../../redux/actions/common.action";
import Cookies from "universal-cookie";
import { baseCookieConfig } from "../../helpers/storage/cookie";

const dev = process.env.NODE_ENV !== "production";

const ReviewsShop = (props) => {
	const dispatch = useDispatch();
	const userId = useSelector(({ user }) => user.user_id);
	const notRatedShops = useSelector(({ common }) => common.notRatedShops);
	const [data, setData] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const handleClose = useCallback(() => {
		setOpenModal(false);
		const cookie = new Cookies();
		const options = {
			...baseCookieConfig,
			maxAge: 3600 * 24 * 14, // 14 days
		};
		cookie.set("review_modal_closed", "1", options);
	}, []);
	const handleClick = useCallback(() => {
		setOpenModal(true);
	}, []);

	const onRateSuccess = useCallback(
		(shopId) => {
			if (notRatedShops.length <= 1) {
				setOpenModal(false);
			}
			dispatch(getNotRatedShops(notRatedShops.filter((shop) => shop.shop_id !== shopId)));
		},
		[notRatedShops]
	);

	useEffect(() => {
		if (notRatedShops.length) return;
		if (userId) {
			axios.get(`/v1/review-platform/action/not-rated/${userId}.json`).then((res) => {
				const data = getOr([], "data.action_review", res) || [];
				const cookies = new Cookies();

				if (data.length && !!!cookies.get("review_modal_closed")) {
					setOpenModal(true);
				}
				dispatch(getNotRatedShops(data));
			});
		}
	}, [userId]);

	return (
		<>
			{userId && !!notRatedShops.length && (
				<div className="container px-3 py-2 bg-blue-100">
					<div>
						<span>
							Gần đây bạn có liên hệ với NCC {""}
							{!!notRatedShops && (
								<span style={{ fontWeight: "bold" }}>
									{notRatedShops
										.filter((value, index, self) => index === self.findIndex((t) => t.shop_name === value.shop_name))
										.slice(0, 3)
										.map((shop) => shop.shop_name)
										.join(", ")}
								</span>
							)}
							. Vui lòng để lại đánh giá để giúp chúng tôi nâng cao chất lượng dịch vụ bạn nhé!
						</span>
					</div>
					<div className="flex justify-end mt-2">
						<Button
							variant="contained"
							disableElevation
							style={{
								backgroundColor: "#f58220",
								textTransform: "initial",
								color: "#fff",
							}}
							onClick={handleClick}
						>
							Đánh giá ngay
						</Button>
					</div>
				</div>
			)}
			<Modal
				title="Đánh giá Nhà Cung cấp"
				open={openModal}
				onClose={handleClose}
				// secondaryAction={{ content: "Bỏ qua", onAction: handleClose }}
			>
				<div>
					<ModalReviewShop shops={notRatedShops.filter((value, index, self) => index === self.findIndex((t) => t.shop_name === value.shop_name))} userId={userId} setOpenModal={setOpenModal} onRateSuccess={onRateSuccess} />
				</div>
			</Modal>
		</>
	);
};
export default ReviewsShop;
