import React, { useState, useEffect, useCallback } from "react";

import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";

const RateTrust = ({ starTrust, setStarTrust, name }) => {
  return (
    <div className="flex justify-center">
      <Box
        component="fieldset"
        mb={5}
        borderColor="transparent"
        style={{ marginBottom: "0px" }}
      >
        <Rating
          style={{ fontSize: "40px" }}
          name={name || "trust"}
          value={starTrust}
          onChange={(event, newValue) => {
            setStarTrust(newValue);
          }}
        />
      </Box>
    </div>
  );
};

export default React.memo(RateTrust);
