import React, { useEffect, useState } from "react";
import Card from "../../components/common/Card";
import { searchProducts } from "../../api/Products";
import ClassifiedProductCard from "../common/ProductCard/Classifieds";
import Router from "next/router";
import { useSelector } from "react-redux";

const Classifieds = props => {
  const classifieds = useSelector(({ homepage }) => homepage.classifieds);
  const [products, setProducts] = useState(classifieds?.products ?? []);

  useEffect(() => {
    if (!classifieds?.products?.length) {
      searchProducts(
        "?filter_only_classifieds=true&limit=6&sort_by=up_at",
        "graphql"
      ).then(({ products }) => {
        setProducts(products);
      });
    }
  }, []);

  return (
    <Card
      title="Đang tuyển NPP, Đại lý, CTV"
      titleUrl="/tim-nha-phan-phoi"
      headerAction={{
        content: "Xem tất cả",
        onAction: () => Router.push("/tim-nha-phan-phoi"),
      }}
    >
      <div className="mb-2 text-gray-500 text-sm">
        Tin đăng từ những doanh nghiệp đang tìm nhà phân phối, đại lý, cộng tác
        viên.
      </div>

      {products.map(product => (
        <ClassifiedProductCard {...product} key={product.product_id} />
      ))}
    </Card>
  );
};

export default Classifieds;
