import React, { useMemo, useCallback, forwardRef } from "react";
import FavoriteIcon from "@material-ui/icons/Favorite";
import StarIcon from "@material-ui/icons/Star";
import Link from "next/link";
import StorefrontIcon from "@material-ui/icons/Storefront";

import isFullUrl from "#Root/helpers/isFullUrl";
import {
  getMinPrice,
  getMaxPrice,
  getMinCompareAtPrice,
  getMaxCompareAtPrice,
} from "../../../helpers/prices";

import { productClickEvent } from "../../../api/Analytics/enhancedEcommerce";
import formatPriceDisplay from "../../../helpers/prices/formatPriceDisplay";
import Image from "./Image";
import NextImage from "next/legacy/image";
import { useDispatch } from "react-redux";
import { prepareProductDetailState } from "../../../redux/actions/products.action";
import MallIcon from "../../../public/static/icons/svg/MallIcon";

const ProductCard = forwardRef(
  (
    {
      id,
      shop_id,
      product_type,
      slug,
      product_id,
      image_thumb,
      title,
      is_deal,
      deal_state,
      price,
      compare_at_price,
      prices,
      min_order_quantity,
      discount,
      unit_name,

      is_vip,
      is_business,

      showVipBusinessLabel = false,
      sponsored = false,
      clickURL,
      onClick,
      listName = "",
      have_better_prices,
      quantity_per_unit,
      is_classifieds,
      classifieds,
      shop,
      total_likes,
      total_inquiries,
      rating_avg,
      recomm_id,
      hide_prices,

      hideCity = false,
      showSupplierName = false,
      leads,

      shop_basic,

      index = null,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const getImageUrl = useMemo(() => {
      if (!image_thumb) {
        return "/static/images/no-image-thumbnail.jpg";
      }
      if (isFullUrl(image_thumb)) {
        return image_thumb;
      }
      return process.env.SCDN_IMAGE_URL + image_thumb;
    }, [image_thumb]);

    const isDeal = is_deal && deal_state === "running";

    const isMall = useMemo(() => {
      return !!shop?.mall;
    }, [shop]);

    const getPrice = useMemo(() => {
      if (hide_prices) {
        return <div className="text-gray-600">Liên hệ</div>;
      }

      const minPrice = formatPriceDisplay(getMinPrice(prices), hide_prices);
      const maxPrice = formatPriceDisplay(getMaxPrice(prices), hide_prices);
      const minCompareAtPrice = formatPriceDisplay(
        getMinCompareAtPrice(prices),
        hide_prices
      );
      const maxCompareAtPrice = formatPriceDisplay(
        getMaxCompareAtPrice(prices),
        hide_prices
      );

      if (isDeal) {
        return (
          <div className="ts-product-card__price--deal">
            <span>
              {minPrice === maxPrice ? minPrice : `${minPrice} - ${maxPrice}`}
              <span className="text-gray-400 font-normal"> / {unit_name}</span>
            </span>

            <div>
              <del style={{ color: "#686868", fontWeight: "normal" }}>
                {minCompareAtPrice === maxCompareAtPrice
                  ? minCompareAtPrice
                  : `${minCompareAtPrice} - ${maxCompareAtPrice}`}
              </del>
            </div>
          </div>
        );
      }
      return (
        <div className="ts-product-card__price--normal">
          {minPrice === maxPrice ? minPrice : `${minPrice} - ${maxPrice}`}
          <span className="text-gray-400 font-normal"> / {unit_name}</span>
        </div>
      );
    }, [
      prices,
      isDeal,
      price,
      compare_at_price,
      discount,
      unit_name,
      hide_prices,
    ]);

    const handleClick = useCallback(e => {
      dispatch(
        prepareProductDetailState({
          id,
          product_id,
          shop_id,
          title,
          image_thumb,
          prices,
          unit_name,
          is_classifieds,
          classifieds,
          shop,
        })
      );
      if (onClick) {
        onClick({ event: e, id, title, slug, product_id });
      }
    }, []);

    return (
      <div className="ts-product-card" ref={ref}>
        <Link
          // prefetch={false}
          href={
            sponsored
              ? clickURL
              : `/product-details?slug=${slug}&id=${product_id}`
          }
          as={sponsored ? clickURL : `/${slug}-${product_id}.html`}
          legacyBehavior
        >
          <a onClick={handleClick}>
            <div className="relative">
              {/* <LazyLoad
                once
                placeholder={
                  <img
                    src="/static/images/preloader.png"
                    className="ts-product-card__thumbnail"
                    alt={title}
                    width={150}
                    height={150}
                  />
                }
                offset={200}
              > */}
              <Image
                alt={title + ` giá sỉ`}
                src={getImageUrl}
                // onError={() =>
                //   (imageRef.current.src =
                //     "/static/images/no-image-thumbnail.jpg")
                // }
                // ref={imageRef}
                loading={index != null && index <= 3 ? "eager" : "lazy"}
                fetchpriority={index != null && index <= 3 ? "high" : "low"}
                decoding="async"
                // width={280}
                // height={280}
                // unoptimized
              />

              {showVipBusinessLabel && (
                <div className="absolute top-1 right-0 -left-2">
                  {is_vip && is_business && (
                    <NextImage
                      layout="fixed"
                      src="/static/icons/DoanhNghiep-Tag.png"
                      width={80}
                      height={23}
                      alt="Doanh Nghiệp"
                      unoptimized
                    />
                  )}
                  {is_vip && !is_business && (
                    <NextImage
                      layout="fixed"
                      src="/static/icons/VIP-Tag.png"
                      width={80}
                      height={23}
                      alt="VIP"
                      unoptimized
                    />
                  )}
                  {!is_vip && !is_business && shop_basic && (
                    <NextImage
                      layout="fixed"
                      src="/static/icons/icon-tieu-chuan.png"
                      width={80}
                      height={23}
                      alt="Basic"
                      unoptimized
                    />
                  )}
                </div>
              )}
              {isDeal && (
                <div className="ts-product-card__discount">{`-${discount}%`}</div>
              )}
              {sponsored && (
                <div className="ts-product-card__sponsored">QC</div>
              )}
              {/* </LazyLoad> */}
            </div>

            <div className="ts-product-card__title text-gray-600">
              {isMall && (
                <MallIcon
                  width={28}
                  height={14}
                  className="inline-block mr-1 mb-1"
                />
              )}
              {title}
            </div>
          </a>
        </Link>
        <div className="ts-product-card__price">{getPrice}</div>
        {have_better_prices && (
          <div className="ts-product-card__better-prices my-1">
            Có thể thương lượng
          </div>
        )}

        {min_order_quantity && (
          <div className="ts-product-card__min-quantity">
            {`Mua sỉ từ ${min_order_quantity.toLocaleString(
              "vi-VN"
            )} ${unit_name?.toLowerCase()}`}
          </div>
        )}

        {showSupplierName && shop?.name && (
          <div className="text-sm text-gray-500 line-clamp-1">
            <StorefrontIcon fontSize="inherit" /> {shop.name}
          </div>
        )}

        {unit_name === "Ri - Dây" && quantity_per_unit > 0 && (
          <div className="ts-product-card__min-quantity">
            <i>{`Mỗi Ri - Dây có ${quantity_per_unit} cái`}</i>
          </div>
        )}

        <div className="my-2"></div>
        <div className="flex justify-between text-gray-500 text-xs mt-auto">
          <div>
            {leads > 0 && (
              <div className="text-green-600">
                {leads} <span>lượt liên hệ</span>
              </div>
            )}
            <div className="flex items-center">
              {total_likes > 0 && (
                <div
                  className={`items-center mt-1 ${
                    rating_avg > 0 ? "mr-1" : ""
                  }`}
                >
                  <FavoriteIcon
                    style={{ fontSize: "1rem", marginRight: "3px" }}
                    color="primary"
                  />
                  {total_likes}
                </div>
              )}
              {rating_avg > 0 && (
                <div className="items-center mt-1">
                  <StarIcon
                    style={{
                      fontSize: "1rem",
                      marginRight: "3px",
                      marginBottom: "2px",
                    }}
                    color="primary"
                  />
                  {rating_avg.toFixed(1)}
                </div>
              )}
            </div>
          </div>
          {shop?.city && !hideCity && (
            <div className="self-end text-xs text-gray-500 text-right">
              {shop.city}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default React.memo(ProductCard);
