import React, { useState, useCallback } from "react";
import aixos from "../../api/axios";
import RateCommunication from "../../components/Review/rate-communication";
import RateProduct from "../../components/Review/rate-product";
import RateTrust from "../../components/Review/rate-trust";
import ReviewComment from "../../components/Review/review-comment";
import ShopReview from "../../components/Review/shop/showshop";
import Toast from "light-toast";
import Button from "@material-ui/core/Button";

const ReviewItem = ({ shopId, userId, setOpenModal, onRateSuccess }) => {
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);

  const [starCommunication, setStarCommunication] = useState(null);
  const [starProduct, setStarProduct] = useState(null);
  const [starTrust, setStarTrust] = useState(null);
  const [comment, setComment] = useState("");

  const handleSubmit = useCallback(() => {
    if (starCommunication != null && starProduct != null && starTrust != null) {
      const data = {
        user_id: userId,
        shop_id: shopId,
        rating: {
          communication: starCommunication,
          product: starProduct,
          trust: starTrust,
          comment: comment,
        },
      };

      aixos
        .post(`/v1/review-platform/review-result.json`, data)
        .then(opt => {
          setData(opt.data);
          Toast.success(data.message || "Cảm ơn bạn đã gửi đánh giá", 2500);
          onRateSuccess(shopId);
        })
        .catch(errors => {
          Toast.hide();
          setErrors(errors);
          Toast.fail(data.message || "Bạn đã gửi đánh giá này rồi", 2500);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Toast.fail(data.message || "Vui lòng đánh giá các tiêu chí", 2500);
    }
  }, [
    userId,
    shopId,
    starCommunication,
    starProduct,
    starTrust,
    comment,
    data,
  ]);

  return (
    <div className="bg-white py-2">
      <div className="col-12 mr-2">
        <div className="col">
          <ShopReview shopId={shopId} />
        </div>
        <div className="text-center">
          <div className="col">
            <b>Giao tiếp, hỗ trợ khách hàng </b>
            <div>
              <span className="text-gray-500">
                (Lịch sự, thân thiện & giải đáp thắc mắc)
              </span>
            </div>
            <RateCommunication
              starCommunication={starCommunication}
              setStarCommunication={setStarCommunication}
              name={`R-communication-${shopId}`}
            />
          </div>

          <div className="col">
            <b>Sản phẩm giống với mô tả</b>
            <div>
              <span className="text-gray-500">
                (Sản phẩm nhận được giống với mô tả)
              </span>
            </div>

            <RateProduct
              starProduct={starProduct}
              setStarProduct={setStarProduct}
              name={`R-product-${shopId}`}
            />
          </div>

          <div className="col">
            <b>Mức độ tin cậy</b>
            <div>
              <span className="text-gray-500">
                (Độ tin cậy của nhà cung cấp)
              </span>
            </div>

            <RateTrust
              starTrust={starTrust}
              setStarTrust={setStarTrust}
              name={`R-trust-${shopId}`}
            />
          </div>

          <div className="col">
            <b>Nhận xét thêm</b>
            <ReviewComment comment={comment} setComment={setComment} />
          </div>
        </div>
      </div>

      <div className="text-center pt-3">
        <Button
          variant="contained"
          disableElevation
          color="primary"
          className="text-center"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {isLoading ? "Vui lòng đợi" : "Gửi đánh giá"}
        </Button>
      </div>
    </div>
  );
};
export default ReviewItem;
