import React, { useState, useEffect } from "react";
import Router from "next/router";

import CircularProgress from "@material-ui/core/CircularProgress";

import Card from "#Root/components/common/Card";
import ProductCard from "#Root/components/common/ProductCard";
import ProductCardList from "../../common/ProductCard/product-card-list";
import { searchProducts } from "#Root/api/Products";
import RequestForQuotation from "../../ProductDetail/RequestForQuotation";

const TrendingProducts = props => {
  const [newProducts, setNewProducts] = useState(props.newProducts);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      searchProducts(
        `?sort_by=trending_score&limit=40&offset=${
          newProducts.products.length + 1
        }`,
        "graphql"
      ).then(({ total, products }) => {
        setNewProducts(old => ({
          total,
          products: [...old.products, ...products],
        }));
        setLoading(false);
      });
    }
  }, [isLoading]);

  useEffect(() => {
    window.addEventListener("scroll", onScrolledToBottom);
    return () => {
      window.removeEventListener("scroll", onScrolledToBottom);
    };
  }, []);

  const onScrolledToBottom = () => {
    let scrolled = window.innerHeight + window.scrollY;
    let totalHeight = document.body.offsetHeight;
    if (
      scrolled >= totalHeight &&
      !isLoading &&
      newProducts.products.length < newProducts.total
    ) {
      setLoading(true);
    }
  };

  return (
    <Card
      title="Sản phẩm đang HOT"
      headerAction={{
        content: "Xem tất cả",
        onAction: () => Router.push("/san-pham-hot"),
      }}
    >
      <ProductCardList>
        {newProducts.products.map((product, index) => (
          <React.Fragment key={product.id}>
            <ProductCard {...product} showVipBusinessLabel />
            {(index + 1) % 40 === 0 && (
              <div className="ts-product-card__cta">
                <RequestForQuotation />
              </div>
            )}
          </React.Fragment>
        ))}
      </ProductCardList>

      {isLoading && (
        <div className="flex justify-center my-4">
          <CircularProgress />
        </div>
      )}
    </Card>
  );
};

export default React.memo(TrendingProducts);
