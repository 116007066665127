import React, { Suspense, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Head from "next/head";
import dynamic from "next/dynamic";

import AppLayout from "../layouts/Layout";
import PromotedSlider from "../components/HomePage/PromotedSlider";
// import CategorySection from "../components/HomePage/CategorySection";
import HotDeals from "../components/HomePage/HotDeals";
import TrendingProducts from "../components/HomePage/TrendingProducts";
// import TradeAssurance from "../components/HomePage/TradeAssurance";
import Navigation from "../components/HomePage/Navigation";
import PrimaryAds from "../components/HomePage/PrimaryAds";
import useInhouseAnalytics from "../hooks/useInhouseAnalytics";
import Classifieds from "../components/HomePage/Classifieds";
// import ScrollToTop from "../components/common/ScrollToTop";
import QuickRFQWidget from "../components/common/QuickRFQWidget";
import { categoryGetRequest } from "../redux/actions/common.action";

// import Category from "../components/HomePage/Category";
// import ShowroomListing from "../components/HomePage/ShowroomListing";
// import RecentProducts from "../components/common/RecentProducts";
import ReviewsShop from "../components/ReviewShop";
import RecommendedForYou from "../components/HomePage/RecommendedForYou/RecommendedForYou";
import Card from "../components/common/Card";
import { wrapper } from "../redux/store";
import { homePageStateGetRequest } from "../redux/actions/homepage.action";
import { END } from "redux-saga";
import Image from "next/legacy/image";
import MallProducts from "../components/HomePage/MallProducts";
import PinnedBlogPosts from "../components/HomePage/PinnedBlogPosts";

const ShowroomListing = dynamic(
  import("../components/HomePage/ShowroomListing")
);
const RecentProducts = dynamic(() =>
  import("../components/common/RecentProducts")
);
const Category = dynamic(import("../components/HomePage/Category"));
const ScrollToTop = dynamic(import("../components/common/ScrollToTop"));

const IndexPage = props => {
  const dispatch = useDispatch();
  const isHomePageLoading = useSelector(
    ({ homepage }) => homepage.isHomePageLoading
  );
  const categoriesLv1 = useSelector(({ common }) => common.categoriesLv1);
  const categoriesLv2 = useSelector(({ common }) => common.categoriesLv2);
  const primaryBanners = useSelector(({ homepage }) => homepage.primaryBanners);
  const secondaryBanners = useSelector(
    ({ homepage }) => homepage.secondaryBanners
  );
  const hotDealProducts = useSelector(
    ({ homepage }) => homepage.hotDealProducts
  );
  const trendingProducts = useSelector(
    ({ homepage }) => homepage.trendingProducts
  );

  const showrooms = useSelector(({ homepage }) => homepage.showrooms);

  useInhouseAnalytics("homepage", {});

  useEffect(() => {
    if (!categoriesLv1.length) {
      dispatch(categoryGetRequest(1));
    }
    if (!categoriesLv2.length) {
      dispatch(categoryGetRequest(2));
    }
  }, []);

  const getCategoryData = useMemo(() => {
    return categoriesLv1.slice(0, 10).map(c => ({
      category_lv1: c,
      categories_lv2: categoriesLv2.filter(cat2 => cat2.parent_id === c.id),
    }));
  }, [categoriesLv1, categoriesLv2]);

  if (isHomePageLoading) {
    if (primaryBanners.length) {
      return (
        <AppLayout
          showHeader
          showFooterBar
          disablePadding
          title="Thị Trường Sỉ - Nguồn hàng bán buôn bán sỉ online tại Việt Nam"
        >
          <PromotedSlider
            items={primaryBanners}
            itemWidth={1280}
            itemHeight={510}
          />
          {secondaryBanners.length > 0 && (
            <div className="mb-2 mt-2">
              <PromotedSlider
                items={secondaryBanners}
                itemWidth={1280}
                itemHeight={287}
              />
            </div>
          )}
          <Navigation />
          <HotDeals hotDealProducts={hotDealProducts} />
        </AppLayout>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <Head>
        <meta
          name="title"
          content="Thị Trường Sỉ - Nguồn hàng bán buôn bán sỉ tại Việt Nam"
        />
        <meta
          content="Chợ sỉ online kết nối trực tiếp với 60.000+ nhà cung cấp uy tín, an toàn. 1 triệu sản phẩm giá tốt giúp bạn kinh doanh hiệu quả."
          name="description"
        />
        <meta
          property="og:image"
          content={process.env.SITE_URL + `/static/images/homepage-og.jpg`}
        />
        <meta
          property="og:title"
          content="Thị Trường Sỉ - Nguồn hàng bán buôn bán sỉ online tại Việt Nam"
        />
        <meta
          property="og:description"
          content="Chợ sỉ online kết nối trực tiếp với 60.000+ nhà cung cấp uy tín, an toàn. 1 triệu sản phẩm giá tốt giúp bạn kinh doanh hiệu quả."
        />
        <meta
          name="keywords"
          content="thitruongsi, cho si online, mua si, ban si, ban buon, mua buon, lay si, nha cung cap, nguon hang, san xuat, gia si, gia ban buon, bo si"
        />
        <meta property="og:url" content={process.env.DESKTOP_SITE_URL} />
        <link rel="canonical" href={process.env.DESKTOP_SITE_URL} />
      </Head>
      <AppLayout
        showHeader
        showFooterBar
        disablePadding
        title="Thị Trường Sỉ - Nguồn hàng bán buôn bán sỉ online tại Việt Nam"
      >
        <ReviewsShop />
        <PromotedSlider
          items={primaryBanners}
          itemWidth={1280}
          itemHeight={510}
        />
        {secondaryBanners.length > 0 && (
          <div className="mb-2 mt-2">
            <PromotedSlider
              items={secondaryBanners}
              itemWidth={1280}
              itemHeight={287}
            />
          </div>
        )}
        <Navigation />
        <HotDeals hotDealProducts={hotDealProducts} />
        <Suspense fallback={<div>Loading...</div>}>
          <MallProducts />
          <RecommendedForYou />
          <PrimaryAds />
          <Classifieds />
          <div className="mt-2 mb-2">
            <QuickRFQWidget />
          </div>

          {getCategoryData.map((c, index) => (
            <Category
              key={index}
              category_lv1={c.category_lv1}
              categories_lv2={c.categories_lv2}
            />
          ))}

          {!props.isSearchEngineBot && <RecentProducts />}
          <TrendingProducts newProducts={trendingProducts} />
          <PinnedBlogPosts />
          <ShowroomListing showrooms={showrooms} />
          <div className="pb-12">
            <Card>
              <div className="my-4 text-sm justify-between">
                <h1 className="text-lg font-bold mb-5">
                  Thị Trường Sỉ - Nguồn hàng sỉ - TMĐT B2B
                </h1>
                <strong>Thị Trường Sỉ</strong> là nền tảng B2B giúp kết nối
                người <strong>mua sỉ</strong> và{" "}
                <strong>bán sỉ (bán buôn)</strong> với nhau một cách dễ dàng.
                Với hơn 900,000 thành viên tham gia, Thị Trường Sỉ là chợ{" "}
                <strong>mua bán sỉ (bán buôn)</strong> có nguồn hàng lớn nhất
                Việt Nam. Với hàng trăm ngàn sản phẩm mua bán sỉ từ những nhà
                cung cấp, nhà phân phối, xưởng may, đại lý lớn với đủ các loại
                hàng hóa như <strong>quần áo thời trang</strong> nam nữ,{" "}
                <strong>giày dép</strong>, phụ kiện (đồng hồ, nước hoa, mắt
                kính,...), gia dụng (đồ dùng gia đình, chăm sóc sức khỏe, nhà
                bếp,...), hitech (điện thoại,{" "}
                <strong>phụ kiện điện thoại</strong>, máy vi tính, laptop,...),
                mẹ và bé (quần áo thời trang bé trai, bé gái),.... Bạn không cần
                phải đến những chợ đầu mối như Chợ Lớn, Chợ Tân Bình, Chợ Kim
                Biên, Chợ An Đông khu vực Tp. Hồ Chí Minh hay chợ Ninh Hiệp, chợ
                Đồng Xuân, hàng Ngang, hàng Đào khu vực Hà Nội vì tất cả tiểu
                thương ở các chợ này đều tham gia mua bán sỉ - bán buôn trên{" "}
                <strong>Thitruongsi.com</strong>.
                <br />
                <br />
                Ngoài ra, bạn có thể gửi{" "}
                <b>
                  <a href="/yeu-cau-bao-gia">yêu cầu báo giá tại đây</a>
                </b>{" "}
                để nhanh chóng nhận được báo giá từ hàng chục ngàn các nhà cung
                cấp trên Thitruongsi.com.
                <br />
                Việc mua bán sỉ (bán buôn) online sẽ giúp bạn tiết kiệm thời
                gian &amp; chi phí, hoạt động giao dịch, mua bán luôn được đảm
                bảo an toàn và bạn có thể mua hàng ở bất cứ đâu.
              </div>

              <div className="mt-5 text-center text-sm">
                <b>© 2014 - BẢN QUYỀN CỦA CÔNG TY CỔ PHẦN DỊCH VỤ TTS</b>
                <br />
                Giấy chứng nhận Đăng ký số 0313203801 cấp tại Sở kế hoạch & đầu
                tư TP Hồ Chí Minh.
                <br />
                Địa chỉ văn phòng: 51 Thép Mới, phường 12, quận Tân Bình, TP Hồ
                Chí Minh.
                <br />
                Email:{" "}
                <b>
                  <a href="mailto:lienhe@thitruongsi.com">
                    lienhe@thitruongsi.com
                  </a>
                </b>{" "}
                | HOTLINE:{" "}
                <b>
                  <a href="tel:19006074">19006074</a>
                </b>
                <a
                  rel="no-follow"
                  target="_blank"
                  href="https:///online.gov.vn/HomePage/WebsiteDisplay.aspx?DocId=18427"
                >
                  <Image
                    layout="fixed"
                    width={192}
                    height={72}
                    src="/static/images/online_gov_vn_seal.jpg"
                  />
                </a>
              </div>
            </Card>
          </div>
          <ScrollToTop />
        </Suspense>
      </AppLayout>
    </>
  );
};

IndexPage.getInitialProps = wrapper.getInitialPageProps(
  store =>
    async ({ req }) => {
      const state = store.getState();
      store.dispatch(homePageStateGetRequest(state.common.isSearchEngineBot));

      if (req) {
        store.dispatch(END);
        await store.sagaTask.toPromise();
      }
      return {};
    }
);

export default IndexPage;
