import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Card from "../../common/Card";
import { getRecommendedProductsForUser } from "../../../api/Products";
import ProductCardList from "../../common/ProductCard/product-card-list";
import ProductCard from "../../common/ProductCard";
import Router from "next/router";
import { useInView } from "react-intersection-observer";
const RecommendedForYou = props => {
  const isLoggedIn = useSelector(({ user }) => Boolean(user.user_id));

  const [recommendedData, setRecommendedData] = useState({
    products: [],
    recomm_id: null,
  });
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (!isLoggedIn || !inView) {
      return;
    }
    getRecommendedProductsForUser({ limit: 6 }).then(res => {
      setRecommendedData(res.data);
    });
  }, [inView]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <Card
      ref={ref}
      title="Sản phẩm dành cho bạn"
      titleUrl="/san-pham-danh-cho-ban"
      headerAction={{
        content: "Xem tất cả",
        onAction: () => Router.push("/san-pham-danh-cho-ban.html"),
      }}
    >
      <ProductCardList>
        {recommendedData.products.map(product => (
          <ProductCard
            key={product.id}
            {...product}
            showVipBusinessLabel
            showSupplierName
            recomm_id={recommendedData.recomm_id}
          />
        ))}
      </ProductCardList>
    </Card>
  );
};
export default RecommendedForYou;
