import React from "react";

import Card from "#Root/components/common/Card";
import ProductCard from "#Root/components/common/ProductCard";
import Link from "next/link";
import useWindowSize from "../../../hooks/useWindowSize";

const HotDeals = props => {
  const size = useWindowSize();

  return (
    <Card
      title="Deal HOT"
      headerAction={{
        content: (
          <Link href="/deal-si" className="text-orange">
            Xem tất cả &raquo;
          </Link>
        ),
      }}
    >
      <div className="flex flex-nowrap overflow-auto hide-scrollbar gap-x-2">
        {props.hotDealProducts.products.map(product => (
          <div className="flex-shrink-0" style={{ width: size.width / 2.3 }}>
            <ProductCard key={product.id} {...product} showVipBusinessLabel />
          </div>
        ))}
      </div>
      {/* <div className="mt-4 text-center">
        <Link href="/deal-si">
          <a className="text-orange font-semibold">
            Xem thêm {props.hotDealProducts.total?.toLocaleString("vi-VN")} sản
            phẩm &raquo;
          </a>
        </Link>
      </div> */}
    </Card>
  );
};

export default React.memo(HotDeals);
