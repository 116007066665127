import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import axios from "../../../api/axios";

import { getShopInfo } from "#Root/api/Shop";
import handleImageDomain from "../../../helpers/images/handleImageDomain";

const ShopReview = ({ shopId }) => {
  const [shop, setShop] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const router = useRouter();
  useEffect(() => {
    if (shopId) {
      getShopInfo(shopId).then(data => {
        setShop(data);
        setLoading(false);
      });
    }
  }, [shopId]);

  return (
    <>
      <div className="flex flex-wrap bg-white p-2 mb-1">
        {!isLoading && (
          <>
            <div className="w-1/5 flex-shrink-0">
              <img
                src={handleImageDomain(shop.avatar)}
                width="50"
                height="50"
              />
            </div>
            <div className="flex-grow">
              <div className="ml-2">
                <div className="font-semibold">{shop.name}</div>
                <div>{shop.province.name}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(ShopReview);
